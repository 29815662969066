import { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '../components/Form/Form.styled';
import { FormGroup } from '../components/FormGroup/FormGroup.styled';
import { Label } from '../components/Label/Label.styled';
import Select from 'react-select';
import { FlexTemplate } from '../components/Templates/FlexTemplate/FlexTemplate.styled';
import { MainContainer } from '../styles/GlobalStyles/GlobalStyle.styled';
import { Button } from '../components/Button/Button.styled';
import { selectStyles } from '../styles/SelectStyles/selectStyles';
import { useInjection } from 'inversify-react';
import { IBusinessFunctionsService } from '../common/services/interfaces/IBusinessFunctionsService';
import { ICountriesService } from '../common/services/interfaces/ICountriesService';
import { IStoresService } from '../common/services/interfaces/IStoresService';
import { SelectItem } from '../common/types';
import { useReduxSelector } from '../common/hooks/hooks';
import { useSearchParams } from 'react-router-dom';
import { Image } from '../components/Image/Image.styled';
import LanguageSelector from '../components/LanguageSelector/LanguageSelector';
import { WelcomeText } from '../components/WelcomeText/WelcomeText.styled';
import { PrivacyText } from '../components/PrivacyText/PrivacyText.styled';
import { GreetingText } from '../components/GreetingText/GreetingText.styled';

export default function Home() {
  const _businessFunctionService: IBusinessFunctionsService = useInjection(
    IBusinessFunctionsService.$
  );

  const _countriesService: ICountriesService = useInjection(ICountriesService.$);
  const _storesService: IStoresService = useInjection(IStoresService.$);

  const locale = useReduxSelector((state) => state.locale);
  const survey = useReduxSelector((state) => state.survey);
  const userInterfaceTranslation = useReduxSelector((state) => state.userInterfaceTranslations);

  const [searchParams, setSearchParams] = useSearchParams();
  const [optionsBusinessFunction, setOptionsBusinessFunction] = useState<SelectItem[]>();
  const [optionsCountry, setOptionsCountry] = useState<SelectItem[]>();
  const [optionsStore, setOptionsStore] = useState<SelectItem[]>();
  const [selectedBusinessFunction, setSelectedBusinessFunction] = useState<SelectItem | null>();
  const [selectedCountry, setSelectedCountry] = useState<SelectItem | null>();
  const [selectedStore, setSelectedStore] = useState<SelectItem | null>();
  const [isDisabledBusinessFunction] = useState<boolean>(false);
  const [isDisabledCountry, setIsDisabledCountry] = useState<boolean>(true);
  const [isDisabledStore, setIsDisabledStore] = useState<boolean>(true);

  const populationId = Number(
    searchParams.get(String(process.env['REACT_APP_POPULATION_ID_PARAM_NAME']))
  );

  useEffect(() => {
    initOptions();
  }, [locale]);

  const initOptions = async () => {
    let businessFunctionOptions = await getBusinessFunctionOptions(locale.value);

    setOptionsBusinessFunction(businessFunctionOptions);

    if (selectedBusinessFunction) {
      setSelectedBusinessFunction(
        getActiveOption(businessFunctionOptions, selectedBusinessFunction.value)
      );
    }

    if (selectedBusinessFunction) {
      let businessFunctionCountryOptions = await getBusinessFunctionCountryOptions(
        locale.value,
        selectedBusinessFunction?.value
      );

      setOptionsCountry(businessFunctionCountryOptions);

      if (selectedCountry) {
        setSelectedCountry(getActiveOption(businessFunctionCountryOptions, selectedCountry.value));
      }
    }

    if (selectedCountry) {
      let businessFunctionCountryStoreOptions = await getBusinessFunctionCountryStoreOptions(
        locale.value,
        selectedCountry?.value,
        populationId
      );

      setOptionsStore(businessFunctionCountryStoreOptions);

      if (selectedStore) {
        setSelectedStore(getActiveOption(businessFunctionCountryStoreOptions, selectedStore.value));
      }
    }
  };

  const getActiveOption = (options: SelectItem[], value: string) => {
    return options.filter((item) => item.value === value)[0];
  };

  const getBusinessFunctionOptions = useCallback(
    async (locale: string) => {
      const response = await _businessFunctionService.getBusinessFunctions(locale);

      return response.map((item) => ({
        value: item.businessFunctionId,
        label: item.name,
      }));
    },
    [locale]
  );

  const getBusinessFunctionCountryOptions = useCallback(
    async (locale: string, businessFunctionId: number) => {
      const response = await _countriesService.getCountries(locale, businessFunctionId);

      return response.map((item) => ({
        value: item.businessFunctionCountryId,
        label: item.name,
      }));
    },
    [locale, selectedBusinessFunction]
  );

  const getBusinessFunctionCountryStoreOptions = useCallback(
    async (locale: string, businessFunctionCountryId: number, populationId: number) => {
      const response = await _storesService.getStores(
        locale,
        businessFunctionCountryId,
        populationId
      );

      return response.map((item) => ({
        value: item.businessFunctionCountryStoreId,
        label: item.name,
      }));
    },
    [locale, selectedCountry, populationId]
  );

  const handleChangeBusinessFunction = useCallback(
    async (selected: any) => {
      setSelectedCountry(null);
      setSelectedStore(null);
      setIsDisabledStore(true);
      setSelectedBusinessFunction(selected);

      let options = await getBusinessFunctionCountryOptions(locale.value, selected.value);

      setOptionsCountry(options);
      setIsDisabledCountry(false);
    },
    [locale]
  );

  const handleChangeCountry = useCallback(
    async (selected: any) => {
      setSelectedStore(null);
      setIsDisabledStore(true);
      setSelectedCountry(selected);

      let options = await getBusinessFunctionCountryStoreOptions(
        locale.value,
        selected.value,
        populationId
      );

      setOptionsStore(options);
      setIsDisabledStore(false);
    },
    [locale]
  );

  const handleChangeStore = useCallback(
    async (selected: any) => {
      setSelectedStore(selected);
    },
    [locale]
  );

  const handleClickButton = async () => {
    if (selectedStore) {
      let codeResponse = await _storesService.getRedirectUrl(
        selectedStore.value,
        survey.value.surveyId,
        locale.value,
        populationId
      );
      let redirectUrl = codeResponse.redirectUrl;
      const url = new URL(window.location.toString());
      window.history.pushState({}, '', url);
      window.location.replace(redirectUrl);
    }
  };

  const renderButton = () => {
    if (selectedStore)
      return (
        <Button onClick={handleClickButton}>{userInterfaceTranslation.value.buttonText}</Button>
      );
    else
      return (
        <Button disabled onClick={handleClickButton}>
          {userInterfaceTranslation.value.buttonText}
        </Button>
      );
  };

  return (
    <MainContainer>
      <FlexTemplate flexDirection="column" justifyContent="space-between" height="100%">
        <LanguageSelector />
        <FlexTemplate flexDirection="column">
          <GreetingText dangerouslySetInnerHTML={{ __html: survey.value.greetingText }} />
          <WelcomeText dangerouslySetInnerHTML={{ __html: survey.value.welcomeText }} />
          <PrivacyText dangerouslySetInnerHTML={{ __html: survey.value.privacyText }} />
          <FlexTemplate>
            <Form margin="60px 0">
              <FormGroup>
                <Label>{survey.value.dropdownOneText}:</Label>
                <Select
                  options={optionsBusinessFunction}
                  value={selectedBusinessFunction}
                  onChange={handleChangeBusinessFunction}
                  styles={selectStyles}
                  isDisabled={isDisabledBusinessFunction}
                  placeholder={userInterfaceTranslation.value.selectPlaceholderText}
                />
              </FormGroup>
              <FormGroup>
                <Label>{survey.value.dropdownTwoText}:</Label>
                <Select
                  options={optionsCountry}
                  value={selectedCountry}
                  onChange={handleChangeCountry}
                  styles={selectStyles}
                  isDisabled={isDisabledCountry}
                  placeholder={userInterfaceTranslation.value.selectPlaceholderText}
                />
              </FormGroup>
              <FormGroup>
                <Label>{survey.value.dropdownThreeText}:</Label>
                <Select
                  options={optionsStore}
                  value={selectedStore}
                  onChange={handleChangeStore}
                  styles={selectStyles}
                  isDisabled={isDisabledStore}
                  placeholder={userInterfaceTranslation.value.selectPlaceholderText}
                />
              </FormGroup>
            </Form>
          </FlexTemplate>
          <FlexTemplate justifyContent="flex-end" margin="0 0 20px 0" width="100%">
            {renderButton()}
          </FlexTemplate>
          <FlexTemplate fontSize="12px" flexDirection="column" gap="30px" margin="0 150px 0 0">
            <Image height="50px" src={survey.value.originLogoUrl} />
          </FlexTemplate>
        </FlexTemplate>
      </FlexTemplate>
    </MainContainer>
  );
}
